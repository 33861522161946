'use client';

/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import {
  shift,
  offset,
  flip,
  autoUpdate,
  useFloating,
  useInteractions,
  useHover,
  useFocus,
  useRole,
  useDismiss,
  useClick,
  arrow,
  FloatingFocusManager,
  FloatingPortal,
} from '@floating-ui/react';
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import remToPx from '@haaretz/s-common-utils/remToPx';
import NoSSR from '@haaretz/s-no-ssr';
import * as React from 'react';
import s9 from 'style9';

import type { Placement, Side, UseDismissProps } from '@floating-ui/react';
import type { StyleExtend, InlineStyles, PolymorphicPropsWithoutRef } from '@haaretz/s-types';

const arrowWidth = space(3);
const borderWidth = '1px';
const arrowOffset = space(1);
const arrowPadding = space(2);
const defaultBgColor = color('neutral100');
const arrowBehindBorderRadius = '3px';
const arrowFrontBroderRadius = '2px';

const c = s9.create({
  elemntsWrapper: {
    '--popoverBrdrColor': color('popoverBorder'),
    position: 'absolute',
    top: 'var(--y)',
    left: 'var(--x)',
    color: `var(--popoverColor, ${color('bodyText')})`,
    zIndex: zIndex('tooltip'),
  },
  inverseVariant: {
    '--popoverColor': color('popoverInverseColor'),
    '--popoverBgColor': color('popoverInverseBg'),
    '--popoverBrdrColor': color('popoverInverseBg'),
    '--popoverArrowBehindColor': color('popoverInverseBg'),
    '--popoverArrowFrontColor': color('popoverInverseBg'),
  },
  popover: {
    backgroundColor: `var(--popoverBgColor, ${defaultBgColor})`,
    borderRadius: radius('medium'),
    ...border({
      width: borderWidth,
      style: 'solid',
      color: 'var(--popoverBrdrColor)',
      spacing: 1,
      side: 'all',
    }),
  },
  arrowKeepInBound: {
    position: 'absolute',
    aspectRatio: '1',
    top: 'var(--arrowY)',
    left: 'var(--arrowX)',
    width: `calc(${arrowWidth} + ${arrowOffset})`,
    transform: 'rotate(45deg)',
  },
  arrowBehind: {
    position: 'absolute',
    aspectRatio: '1',
    top: `calc(var(--arrowY) + (${arrowOffset} * 0.5))`,
    left: `calc(var(--arrowX) + (${arrowOffset} * 0.5))`,
    width: `${arrowWidth}`,
    backgroundColor: `var(--popoverArrowBehindColor, ${color('popoverBorder')})`,
    transform: 'rotate(45deg)',
    zIndex: zIndex('below'),
  },
  arrowBehindBorderRadiusBlock: {
    borderTopLeftRadius: arrowBehindBorderRadius,
    borderBottomRightRadius: arrowBehindBorderRadius,
  },
  arrowBehindBorderRadiusInline: {
    borderTopRightRadius: arrowBehindBorderRadius,
    borderBottomLeftRadius: arrowBehindBorderRadius,
  },
  arrowFront: {
    position: 'absolute',
    aspectRatio: '1',
    top: `calc(var(--arrowY) + ${borderWidth} + (${arrowOffset} * 0.5))`,
    left: `calc(var(--arrowX) + ${borderWidth} + (${arrowOffset} * 0.5))`,
    width: `calc(${arrowWidth} - (${borderWidth} * 2))`,
    backgroundColor: `var(--popoverArrowFrontColor, ${defaultBgColor})`,
    transform: 'rotate(45deg)',
    zIndex: 2,
  },
  arrowFrontBorderRadiusBlock: {
    borderTopLeftRadius: arrowFrontBroderRadius,
    borderBottomRightRadius: arrowFrontBroderRadius,
  },
  arrowFrontBorderRadiusInline: {
    borderTopRightRadius: arrowFrontBroderRadius,
    borderBottomLeftRadius: arrowFrontBroderRadius,
  },
  topArrowPadding: {
    paddingTop: arrowPadding,
  },
  bottomArrowPadding: {
    paddingBottom: arrowPadding,
  },
  leftArrowPadding: {
    paddingLeft: arrowPadding,
  },
  rightArrowPadding: {
    paddingRight: arrowPadding,
  },
});

type NumberOrRem = number | `${number}rem`;
export type OffsetOptions = {
  /**
   * The axis that runs along the side of the floating element. Represents
   * the distance (gutter or margin) between the reference and floating
   * element.
   * @defaultValue 0
   */
  mainAxis?: NumberOrRem;
  /**
   * The axis that runs along the alignment of the floating element.
   * Represents the skidding between the reference and floating element.
   * @defaultValue 0
   */
  crossAxis?: NumberOrRem;
  /**
   * The same axis as `crossAxis` but applies only to aligned placements
   * and inverts the `end` alignment. When set to a number, it overrides the
   * `crossAxis` value.
   *
   * A positive number will move the floating element in the direction of
   * the opposite edge to the one that is aligned, while a negative number
   * the reverse.
   * @defaultValue null
   */
  alignmentAxis?: NumberOrRem | null;
};

export interface PopoverOwnProps {
  /** The Children to be rendered inside `<Popover>` */
  children?: React.ReactNode;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyPopover(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Popover
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Popover styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * The element's ref the popover refers to.
   * Hover, click and focus on this element will make
   * the popover open.
   */
  refersToRef: React.MutableRefObject<Element | null>;
  /**
   * Places the popover element relatively to the chosen side.
   *
   * @defaultValue 'top'
   */
  placement?: Placement;
  /**
   * Controls the appearance of the popover.
   *
   * @defaultValue 'default'
   */
  variant?: 'default' | 'inverse';
  /**
   * Determines on what condition will the popover open.
   */
  openOn?: 'click' | 'hover+focus' | 'focus' | 'hover' | 'controlled';
  /**
   * Changes the anchoring position of the popover to keep it in view.
   *
   * @defaultValue true
   */
  keepInView?: boolean;
  /**
   * Determines if the popover should be open or not.
   */
  isOpen?: boolean;
  /**
   * Determines if should behave as a popover or a tooltip.
   */
  kind?: 'popover' | 'tooltip';
  /**
   * The default state of IsOpen, useful for things such as keeping
   * the component uncontrolled but having the popover already opened
   * on mount.
   */
  defaultIsOpen?: boolean;
  /**
   * Callback to make the component controlled, allows you to use
   * the internal state.
   */
  onToggle?: (isOpen: boolean) => void;
  /**
   * Determines on what condition will the popover close.
   */
  closeOn?: 'esc' | 'blur' | 'esc+blur' | 'controlled';
  /**
   * A placement modifier that translates the floating element along the axis.
   * Check 'OffsetOptions' for more info.
   * Supports 'rem' unit. It is suggested to use "space" macro for this.
   */
  offsetValue?: NumberOrRem | OffsetOptions;
}

export const DEFAULT_ELEMENT = 'div';
type AllowedElements = 'div' | 'section';

export type PopoverProps<As extends React.ElementType = AllowedElements> =
  PolymorphicPropsWithoutRef<PopoverOwnProps, As, AllowedElements>;

function Popover<As extends React.ElementType = AllowedElements>(props: PopoverProps<As>) {
  if (typeof window === 'undefined') return null;

  return <PopoverClient {...props} />;
}

let rootFontSize: number | undefined;
function PopoverClient<As extends React.ElementType = AllowedElements>({
  as,
  children = null,
  inlineStyle,
  styleExtend = [],
  refersToRef,
  variant = 'default',
  placement = 'top',
  openOn: openOnProp,
  keepInView,
  isOpen: isOpenProp,
  kind = 'popover',
  defaultIsOpen,
  onToggle,
  closeOn = 'esc+blur',
  offsetValue = 0,
  ...attrs
}: PopoverProps<As>) {
  const popoverId = React.useId();
  const Element: React.ElementType = as || DEFAULT_ELEMENT;
  const arrowRef = React.useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState(defaultIsOpen || false);
  const open = isOpenProp != null ? isOpenProp : isOpen;
  const isInverse = variant === 'inverse';

  rootFontSize =
    rootFontSize == null
      ? Number.parseInt(getComputedStyle(document.documentElement).fontSize)
      : rootFontSize;

  const offsetOptions = getOffsetOptions(offsetValue);

  if (isOpenProp != null && !onToggle) {
    console.warn(
      `<Popover>: You set a value to "isOpen", making it a controlled component,
      but did not set an "onToggle" handler,
      making the controlling component unaware of internal changes.`
    );
  }

  const isPopover = kind === 'popover';
  const openOn = openOnProp ? openOnProp : isPopover ? 'click' : 'hover+focus';
  const showOnHover = openOn === 'hover+focus' || openOn === 'hover';
  const showOnFocus = openOn === 'hover+focus' || openOn === 'focus';
  const showOnClick = openOn === 'click';
  const closeOnBlur = closeOn === 'blur' || closeOn === 'esc+blur';
  const closeOnOptions: UseDismissProps = {
    enabled: closeOn !== 'controlled',
    escapeKey: closeOn === 'esc' || closeOn === 'esc+blur',
    outsidePress: closeOnBlur,
  };
  const hasChildren = children !== null;

  const handleOpenStateChange = React.useCallback(
    (isOpenFromLibrary: boolean) => {
      if (onToggle) onToggle(isOpenFromLibrary);
      else setIsOpen(isOpenFromLibrary);
    },
    [onToggle]
  );

  const {
    x,
    y,
    context,
    middlewareData,
    refs,
    placement: popoverPlacement,
  } = useFloating({
    placement,
    open,
    onOpenChange: handleOpenStateChange,
    middleware: [
      offset(offsetOptions),
      ...(keepInView ? [shift(), flip()] : []),
      ...(hasChildren ? [arrow({ element: arrowRef })] : []),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { enabled: showOnHover }),
    useFocus(context, { enabled: showOnFocus }),
    useClick(context, { enabled: showOnClick }),
    useRole(context, { role: 'dialog' }),
    useDismiss(context, closeOn && closeOnOptions),
  ]);

  const arrowSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[popoverPlacement.split('-')[0]] as Side;

  const arrowX = React.useMemo(() => middlewareData.arrow?.x, [middlewareData.arrow?.x]);
  const arrowY = React.useMemo(() => middlewareData.arrow?.y, [middlewareData.arrow?.y]);
  const arrowBehindPosition = `calc((${arrowWidth} * -0.5) + ${borderWidth} + ${arrowPadding})`;
  const arrowFrontPosition = `calc((${arrowWidth} * -0.5) + (${borderWidth} * 2 + ${arrowPadding}))`;
  const arrowKeepInBoundPosition = `calc((${arrowWidth} * -0.7) + ${borderWidth} + ${arrowPadding})`;

  React.useLayoutEffect(() => {
    const refersToElement = refersToRef?.current;

    const {
      onClick,
      onKeyDown,
      onKeyUp,
      onMouseDown,
      onPointerDown,
      onFocus,
      onBlur,
      onMouseMove,
      onPointerEnter,
      ...refAttrs
    } = getReferenceProps();

    const events = {
      click: onClick,
      keydown: onKeyDown,
      keyup: onKeyUp,
      mousedown: onMouseDown,
      pointerdown: onPointerDown,
      focus: onFocus,
      blur: onBlur,
      mousemove: onMouseMove,
      pointerenter: onPointerEnter,
    };

    if (refersToElement) {
      refs.setReference(refersToElement);
      refersToElement.setAttribute('aria-describedby', popoverId);

      Object.entries(events).forEach(([event, fn]) => {
        if (typeof fn === 'function') {
          refersToElement.addEventListener(event, fn as () => void);
        }
      });

      Object.entries(refAttrs).forEach(([attr, value]) => {
        if (typeof value !== 'function' && value != null) {
          refersToElement.setAttribute(attr, value as string);
        }
      });
    }

    return () => {
      if (refersToElement) {
        refersToElement.removeAttribute('aria-describedby');
        Object.entries(events).forEach(([event, fn]) => {
          if (typeof fn === 'function') {
            refersToElement.removeEventListener(event, fn as () => void);
          }
        });

        Object.entries(refAttrs).forEach(([attr, value]) => {
          if (typeof value !== 'function') {
            refersToElement.removeAttribute(attr);
          }
        });
      }
    };
  }, [getReferenceProps, popoverId, refersToRef, refs]);

  if (!refersToRef?.current) return null;

  const ArrowPaddingClass: `${Side}ArrowPadding` = `${arrowSide}ArrowPadding`;
  const isBlockPlacement = popoverPlacement.includes('top') || popoverPlacement.includes('bottom');
  const popoverJsx = (
    <div
      className={s9(
        c.elemntsWrapper,
        arrowSide && c[ArrowPaddingClass],
        isInverse && c.inverseVariant
      )}
      ref={refs.setFloating}
      style={
        {
          '--y': `${y}px`,
          '--x': `${x}px`,
          ...inlineStyle,
        } as React.CSSProperties
      }
      {...getFloatingProps()}
    >
      {hasChildren && (
        <>
          <Element
            id={popoverId}
            role={kind === 'tooltip' ? 'tooltip' : 'region'}
            data-testid="popover"
            className={s9(c.popover, ...styleExtend)}
            {...attrs}
          >
            {children}
          </Element>
          <div
            style={
              {
                '--arrowX': arrowX != null ? `${arrowX}px` : '',
                '--arrowY': arrowY != null ? `${arrowY}px` : '',
              } as React.CSSProperties
            }
          >
            <div
              className={s9(c.arrowKeepInBound)}
              ref={arrowRef}
              style={{
                ...(arrowSide && { [arrowSide]: arrowKeepInBoundPosition }),
              }}
            />
            <div
              className={s9(
                c.arrowBehind,
                isBlockPlacement ? c.arrowBehindBorderRadiusBlock : c.arrowBehindBorderRadiusInline
              )}
              style={{
                ...(arrowSide && { [arrowSide]: arrowBehindPosition }),
              }}
            />
            <div
              className={s9(
                c.arrowFront,
                isBlockPlacement ? c.arrowFrontBorderRadiusBlock : c.arrowFrontBorderRadiusInline
              )}
              style={{ ...(arrowSide && { [arrowSide]: arrowFrontPosition }) }}
            />
          </div>
        </>
      )}
    </div>
  );

  return (
    <NoSSR>
      <FloatingPortal>
        {open &&
          (isPopover ? (
            <FloatingFocusManager
              context={context}
              modal={!closeOnBlur}
              order={['content', 'floating', 'reference']}
            >
              {popoverJsx}
            </FloatingFocusManager>
          ) : (
            popoverJsx
          ))}
      </FloatingPortal>
    </NoSSR>
  );
}

export default Popover;

function getOffsetOptions(offsetValue: PopoverProps['offsetValue']) {
  if (typeof offsetValue === 'number') {
    return offsetValue;
  }

  if (typeof offsetValue === 'string') {
    return remToPx(offsetValue);
  }

  if (typeof offsetValue === 'object') {
    type OffsetValueKeys = keyof typeof offsetValue;

    const offsetValueInPx: Partial<Record<OffsetValueKeys, number>> = {};

    // eslint-disable-next-line guard-for-in
    for (const key in offsetValue) {
      const value = offsetValue[key as OffsetValueKeys];

      if (typeof value === 'string') {
        offsetValueInPx[key as OffsetValueKeys] = remToPx(value);
      }
    }

    return offsetValueInPx;
  }

  return 0;
}
