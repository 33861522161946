import config from '@haaretz/l-config';

const searchAffiliateId = config.get('searchAffiliateId');

export type SearchPayloadType = {
  q: string;
  attribute?: string;
  p?: number;
  inst?: boolean;
} & (
  | {
      domain: string;
      h?: unknown;
    }
  | {
      h: string;
      domain?: unknown;
    }
);

export interface DefaultSearchResponseType {
  res?: number;
  error?: string;
}

export default async function fetcher<T extends DefaultSearchResponseType>(
  path: string,
  requestJson: SearchPayloadType
): Promise<T> {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...requestJson,
      affId: searchAffiliateId,
    }),
  };

  try {
    const response = await fetch(`https://heyday.io${path}`, options);

    if (response.ok) {
      const data = await response.json();

      if (data) {
        return data as T;
      }
    } else {
      throw new Error('Heyday search error');
    }
  } catch (error) {
    console.warn((error as Error).message);
  }
  return { res: 0, error: 'Heyday search error' } as T;
}
